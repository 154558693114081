.card {
  background-color: #fff;
  padding: 7px 10px;
  margin-bottom: 7px;
}
.orders-row h3 {
  font-size: 1.25rem;
  font-weight: bold;
}
.red {
  background-color: #eb3223;
  padding: 8px;
  border-radius: 14px;
}
.yellow {
  background-color: #fadd4f;
  padding: 8px;
  border-radius: 14px;
}
.green {
  background-color: #519b4c;
  padding: 8px;
  border-radius: 14px;
}
.blue {
  background-color: #5e8eca;
  padding: 12px;
  border-radius: 14px;
}
.yellow h3,
.green h3,
.gray h3 {
  color: #000;
}
.cover {
  overflow-y: auto;
  /* max-height: 65vh; */
}
.gray {
  background-color: #84838e;
  padding: 8px;
  border-radius: 14px;
  color: #84838e;
}
.row.orders-row {
  flex-wrap: nowrap;
}
.color-bg {
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 8px;
  position: absolute;
  top: 10px;
  right: 0;
  bottom: 0;
  left: 0;
}

@media (max-width: 992px) {
  .row.orders-row {
    /* flex-wrap: wrap; */
  }
  .color-bg .yellow,
  .red,
  .blue,
  .gray,
  .green {
    margin-bottom: 1rem;
  }
  .cover {
    max-height: 500px;
  }
  .color-bg {
    top: 10px;
    overflow-y: visible;
  }
}
