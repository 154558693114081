body {
  background-color: #000 !important;
  margin: 0;
  padding: 0;
  overflow-y: hidden;
  overflow-x: hidden;
}
@media (max-width: 992px) {
  body {
    overflow-y: hidden;
  }
}
h3 {
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
  color: #fff;
  letter-spacing: 1.5px;
}
