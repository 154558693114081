.button.secondry {
  border-radius: 14px;
  border: none;
  background-color: #fadd4f;
  font-weight: bold;
  padding: 14px 0;
  width: 45%;
}

.button.primary {
  font-weight: bold;
  background-color: #fff;
  color: #000;
  border: none;
  padding: 14px 0;
  width: 45%;
  border-radius: 14px;
}

.button.view {
  border-radius: 10px;
  background-color: #fadd4f;
  color: #000;
  border: none;
  font-weight: bold;
  width: 40%;
  padding: 0.4rem 0;
}
.button.accept {
  border-radius: 10px;
  background-color: #eb3223;
  color: #fff;
  border: none;
  font-weight: bold;
  width: 40%;
  padding: 0.4rem 0;
}
.button.complete {
  width: 40%;
  padding: 0.4rem 0;
  border-radius: 10px;
  background-color: #519b4c;
  color: #fff;
  border: none;
  font-weight: bold;
}

.button.ready {
  width: 40%;
  padding: 0.4rem 0;
  border-radius: 10px;
  background-color: #5e8eca;
  color: #000;
  border: none;
  color: #fff;
  font-weight: bold;
}

.button.modal-blue {
  width: 40%;
  padding: 14px 0;
  border-radius: 5px;
  background-color: #5e8eca;
  color: #fff;
  border: none;
  font-weight: bold;
  margin-right: 0.5rem;
}
.button.modal-red {
  width: 40%;
  padding: 14px 0;
  border-radius: 5px;
  background-color: #eb3223;
  color: #fff;
  border: none;
  font-weight: bold;
  margin-left: 0.5rem;
}
