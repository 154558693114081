.preloader {
  width: 24px;
  height: 24px;
  position: relative;
}

.preloader span {
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background-color: #519b4c;
  animation: preloader 1.5s linear infinite;
}
.preloader span:nth-child(2) {
  animation-delay: -0.6s;
}
@keyframes preloader {
  from {
    transform: scale(0);
    opacity: 0.9;
  }
  to {
    transform: scale(1);
    opacity: 0;
  }
}
