.form label {
  color: #fff;
}
.form input {
  padding: 1.5rem 1rem;
  border-radius: 14px;
}
.form input:focus {
  border-color: none;
  box-shadow: none;
}
.form .h3 {
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  color: #fff;
}

.form .btn.btn-primary.btn-block {
  background-color: #fadd4f;
  border: none;
  color: #000;
  font-weight: bold;
  padding: 1rem 1rem;
  border-radius: 7px;
}

.form a:hover {
  color: none;
  text-decoration: none;
}

.form .btn.btn-primary.btn-block:hover {
  background-color: #fadd4f;
}
.form .btn.btn-primary.btn-block:active:focus {
  color: #000;
  background-color: #fadd4f;
  border: none;
  box-shadow: none;
}
.form .btn.btn-primary.btn-block:focus {
  box-shadow: none;
}
