.card {
  font-family: Arial, Helvetica, sans-serif;
}
.card p {
  margin-bottom: 0.1rem;
}
.card .h5 {
  font-size: 1rem;
  font-weight: bold;
}
.card .h6 {
  font-size: 0.8rem;
  font-weight: 600;
}
.card span {
  color: #84838e;
}
