svg {
  color: #fff;
  font-size: 1.2rem;
  margin-left: 10px;
}

.topbar .h3,
a {
  color: #fff;
  text-decoration: none;
}
