.modal-content {
  font-family: Arial, Helvetica, sans-serif;
}
.modal-top .h4 {
  font-weight: bold;
  font-size: 1.5rem;
  letter-spacing: 1.3px;
  margin-bottom: 0px;
  color: #fff;
}
.modal-top {
  margin-bottom: 0.5rem;
}

.modal-top a {
  text-decoration: none;
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 2px;
  color: #5e8eca;
}
.modal-top a:hover {
  text-decoration: none;
  color: #5e8eca;
}
.modal-title {
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #fadd4f;
}
.modal-dialog .modal-content .form-control:focus {
  border-color: unset;
  outline: 0;
  box-shadow: none;
}
.remove-order p {
  color: #fff;
}
.modal-dialog.modal-25w.modal-dialog-centered {
  width: 25%;
}
.modal-dialog .modal-content {
  border-radius: 24px;
  background-color: #1d1830;
}
.order-description .h4 {
  font-weight: bold;
  font-size: 1rem;
  color: #fff;
}

.modal-body .h5 {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0;
  color: #fff;
}
.modal-body .h3 {
  color: #fff;
  font-weight: bold;
}
.order-details .h5 {
  color: #5e8eca;
  margin-bottom: 1rem;
}
.order-details-title .h3 {
  color: #fadd4f;
}
.order-details-title {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.order-details-title .h6 {
  color: #fff;
  font-weight: bold;
  font-size: 0.8rem;
}
.ordertable {
  background-color: #fff;
  padding: 7px 10px;
  width: 100%;
  /* height: 135px; */
}
.modal-row {
  overflow-y: scroll;
  max-height: 100%;
}
.ordertable .h5,
.h4,
.h6 {
  color: #000;
}
.ordertable span {
  font-size: 0.8rem;
}
.ordertable .ice {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.totle-table {
  border: 1px solid #fff;
}
.totle-table span {
  font-size: 0.8rem;
}
.totle-table .h6 {
  margin-bottom: 0;
}
.totle-table .h3 {
  color: rgb(231, 226, 226);
}
.totle-table2 {
  border-top: 1px solid #fff;
  padding: 10px;
}
.totle-table1 {
  padding: 10px;
}
.order-description2 {
  margin: 1rem 0;
  padding: 1rem 0;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}
.confirm-order {
  margin: 1rem 0;
}
.modal-body span {
  color: #84838e;
}

.modal-top span {
  margin: 5rem 0;
}

.remove-order .modal-title {
  margin-bottom: 1rem;
}

.remove-order .h4 {
  margin: 1rem 0;
}
.modal-input .form-control {
  width: 90%;
}
.order-table-1 {
  padding-right: unset;
}
@media (max-width: 992px) {
  .order-table-1 {
    margin-bottom: 1rem;
  }
  .modal-dialog.modal-25w.modal-dialog-centered {
    width: 100%;
  }
}
