.filter-box {
  position: relative;
  width: 60%;
}
.filter-box svg {
  position: absolute;
  left: 8px;
  top: 10px;
  color: #000;
  font-size: 24px;
}
.filter-box input[type="text"] {
  padding: 8px;
  padding-left: 45px;
  background-color: #fff;
  border: none;
  border-radius: 10px;
  font-size: 18px;
  width: 100%;
  color: #000;
}

/* @media (max-width: 768px) {
  .filter-box {
    width: 100%;
  }
  .filter-box svg {
    left: 63px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .filter-box svg {
    left: 85px;
  }
} */
