.search_box {
  position: relative;
}

.search-box input[type="text"] {
  width: 100%;
  padding: 8px;
  padding-left: 40px;
  background-color: #fff;
  border: none;
  border-radius: 10px;
  font-size: 18px;
  color: #000;
}

.search-box svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  color: #000;
  font-size: 25px;
}
